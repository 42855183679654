export const SWAP_DIRECTIONS_DIRECT = {
    "adm": "ton",
    //"adm": "usdt",
    "usdt": "adm",
    "nul": "usdt"
}

export const SWAP_DIRECTIONS_REVERS = {
    "ton": "adm",
    "usdt": "adm",
    "adm": "usdt"
}

export const SWAP_CURRENCIES_NAMES = {
    'ton': 'TON',
    'adm': 'ADM',
    'usdt': "USDT",
    'nul': "Наличные"
}

export const SWAP_CURRECIES_LOGO = {
    'ton': './images/ton_symbol.png',
    'adm': './images/adm-logo-1.jpg',
    'usdt': "./images/usdt-logo.webp",
    'nul': "./images/nal-logo.jpg"
}

export const ADM_PRICE = 388
export const TON_CONNECT_MANIFECT_URL = "https://swap.vomer.ru/tonconnect-manifest.json"

export const API = 'https://api.vomer.ru'
//export const API = 'http://localhost:5000'

//export const USDT_DIGITALS = 9 //testnet
//export const TON_DIGITALS = 9 //testnet
//export const ADM_DIGITALS = 9 //testnet


export const USDT_DIGITALS = 6  //mainnet
export const TON_DIGITALS = 9  //mainnet
export const ADM_DIGITALS = 2  //mainnet


//export const OWNER_ADDRESS = "0QAlJbJC7iMS5jFQQjTxSf3DkJP7SNOIgq_XViwnXt5G5up-" //testnet
export const OWNER_ADDRESS = "UQA08drz5VC0WM6TZlKgHtRJ11JcvCynv3ZGY8YJ6-in9EB1" //mainnet





